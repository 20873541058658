import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Cover = () => {
  return (
    <div className="bg-div" id="home">
      <header className="head mx-5">
        <div className="head-div mx-auto main-div">
          <section className="div-1">
            <h1 className="head-div-second-text">Welcome to Davnix Tech</h1>
            <p className="head-div-fourth-text">
              We are Davnix - Where Precision Meets Promise in Software Product Development! 💻✨
              Join us in the journey of crafting cutting-edge software products that redefine possibilities. 
              Precision, Promise, and Passion in every line of code. 💼💻 #DavnixTech
            </p>
          </section>
        </div>
      </header>
      <span className="scroll-btn">
        <a href="#home">
          <span className="mouse">
            <span></span>
          </span>
        </a>
      </span>
      <nav className="social-media">
        <ul className="social-media-list">
          {SocialData.map((data, ind) => {
            return (
              <li key={ind}>
                <a
                  className="social-media-list-link"
                  href={data.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Follow us on ${data.label}`}
                >
                  <FontAwesomeIcon icon={data.icon} className="media-icons" />
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Cover;

const SocialData = [
  {
    link: "https://www.linkedin.com/company/davnix-tech/",
    icon: faLinkedin,
    label: "LinkedIn",
  },
  {
    link: "https://www.instagram.com/davnix__tech/",
    icon: faInstagram,
    label: "Instagram",
  },
  {
    link: "https://www.twitter.com/davnix_tech/",
    icon: faXTwitter,
    label: "Twitter",
  }
];
