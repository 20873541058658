import React from 'react';

const Quote = () => {
    return (
        <div className="quote-div">
            <header className="head mx-5">
                <div className="main-div">
                    <section className="div-1 quote">
                        <blockquote className="my-3">
                            “Rising from the ashes, the Phoenix soars, embodying resilience, strength, and a fiery spirit that never wanes.”
                        </blockquote>
                    </section>
                </div>
            </header>
        </div>
    );
};

export default Quote;
