import React from "react";
import CardComponent from "./card-component";
import Pro1 from "../assets/img/project-1.png";

const Projects = () => {
  return (
    <div
      style={{ background: "#0b0b0d", color: "white" }}
      className="main-div"
      id="project"
    >
      <section className="div-1">
        <h1 className="heading">Our Projects</h1>
        <div>
          {ProjectData.map((data, ind) => {
            const { linkedin, external, img, title, text, skills } = data;
            return (
              <CardComponent
                key={ind}
                linkedin={linkedin}
                external={external}
                img={img}
                title={title}
                text={text}
                skills={skills}
              />
            );
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a
            href="https://www.linkedin.com/in/abhijeethrkgadwal/"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="btn btn-outline-light"
            style={{ width: "fit-content" }}
          >
            More Projects
          </a>
        </div>
      </section>
    </div>
  );
};

export default Projects;

const ProjectData = [
  {
    title: "NamNews",
    img: Pro1,
    text: "A revolutionary digital platform for small-scale regional news publishers.",
    external: "https://www.namnews.in/",
    linkedin: "https://www.linkedin.com/company/namnews"
  }
];
