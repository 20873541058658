import React from 'react';

const About = () => {
    return (
        <div
            style={{ background: "#0b0b0d", color: "white" }}
            className="main-div"
            id="about"
        >
            <section className="div-1 about">
                <h1 className="heading">About Davnix Tech</h1>
                <div>
                    <p className="text mt-4 mb-3">
                        At Davnix Tech, we don't just code - we compose precision.
                        Our software solutions are more than lines of code – they're symphonies of innovation.
                        Committed to excellence, we don't promise, we deliver.
                        In the realm of technology, trust Davnix to turn possibilities into realities,
                        where precision meets performance, and commitment meets cutting-edge creativity.
                    </p>
                </div>
                <p className="text mt-3">
                    Some of our expertise includes:
                </p>
                <div className="skills-box">
                    <ul className="lists">
                        {["Web/Mobile App Development", "Software Development", "Product Development"].map((text) => {
                            return (
                                <li className="my-2 list-item" key={text}>
                                    {text}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default About;
