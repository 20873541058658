import React, { useEffect } from 'react';
import Cover from './components/cover';
import NavbarComponent from './components/navbar';
import About from './components/about';
import Quote from './components/quote';
import Projects from './components/projects';
import Contact from './components/contact';
import Loading, { loadingListener } from './components/loader';

const App = () => {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });

    loadingListener();
  }, []);

  return (
    <div className="app">
      <Loading />
      <NavbarComponent />
      <Cover />
      <About />
      <Quote />
      <Projects />
      <Contact />
    </div>
  );
};

export default App;
