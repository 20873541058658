import React from "react";
import { Container } from "react-bootstrap";
import { faLinkedin, faChrome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardComponent = ({ linkedin, external, img, title, text, skills }) => {
  return (
    <Container>
      <div className="container card my-5">
        <ul className="social-links ul">
          <li>
            <a href={linkedin} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn Profile">
              <FontAwesomeIcon icon={faLinkedin} className="media-icons" />
            </a>
          </li>
          {external && (
            <li>
              <a href={external} target="_blank" rel="noopener noreferrer" aria-label="External Link">
                <FontAwesomeIcon icon={faChrome} className="media-icons" />
              </a>
            </li>
          )}
        </ul>
        <img src={img} alt={`${title} Image`} />
        <div className="overlay"></div>
        <div className="con-text">
          <a href={external} target="_blank" rel="noopener noreferrer">
            <h2>{title}</h2>
          </a>
          <p>{text}</p>
          <p>{skills}</p>
        </div>
      </div>
    </Container>
  );
};

export default CardComponent;
