import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../assets/img/logo-no-background.png";

const NavbarComponent = () => {
  const changeBackground = () => {
    let header = document.getElementsByClassName("header-nav")[0];
    if (window.scrollY < 200) {
      header.classList.remove("sticky");
    } else {
      header.classList.add("sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <header>
      <Navbar
        className="header-nav"
        fixed="top"
        collapseOnSelect
        bg="dark"
        expand="lg"
      >
        <Navbar.Brand href="#home" className="brand-name ml-5">
          <img
            src={logo}
            alt="Davnix Tech Logo"
            height="30"
            style={{ margin: "10px" }}
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{ flexDirection: "row-reverse" }}
        >
          <Nav className="ml-auto mr-5">
            <Nav.Link className="px-3" href="#home">
              Home
            </Nav.Link>
            <Nav.Link className="px-3" href="#about">
              About
            </Nav.Link>
            <Nav.Link className="px-3" href="#projects">
              Projects
            </Nav.Link>
            <Nav.Link className="px-3" href="#contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default NavbarComponent;
