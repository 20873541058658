import React from "react";

const Contact = () => {
  return (
    <div className="contact-div" id="contact">
      <header className="head mx-5">
        <div className="main-div">
          <section className="div-1 contact">
            <h1 className="my-3 heading" style={{ textAlign: "center" }}>
              Get In Touch
            </h1>
            <p className="contact-description">
              Want to catch up on tech solutions?
              <br /> Email: 
              <a
                href="mailto:contact@davnix.com"
                aria-label="Davnix Tech email id"
              >
                 contact@davnix.com
              </a>
            </p>
            <a
              href="mailto:contact@davnix.com"
              className="button btn btn-outline-success my-3"
              aria-label="Send an email to Davnix Tech"
            >
              Say Hello
            </a>
          </section>
          <footer className="footer">
            <p style={{ textAlign: "center" }}>
              <strong>CIN : U62013KA2024PTC185381</strong>
            </p>
            <p>© 2024 Davnix Tech Private Limited. All rights reserved.</p>
          </footer>
        </div>
      </header>
    </div>
  );
};

export default Contact;
